import moonSvg from "~/img/svg/moon.svg";
import sunSvg from "~/img/svg/sun.svg";
import "~/css/style.css";
import { getSourcesStatus, getUserReportModal } from "~/js/api.js";
import { isMobile, isTablet } from "~/js/helpers";
import { Dialog, UserReportDialog } from "~/js/utils/dialog.js";
import { Popper } from "~/js/utils/popper";

async function initDialogSourcesStatus() {
	const openButton = document.querySelector("#dSSOpenButton");
	if (!openButton) return;

	const dialog = new Dialog({
		classes: "min-h-[650px]",
		id: "sourceStatusDialog",
		open: async (dialog) => {
			getSourcesStatus().then((data) => {
				setTimeout(() => {
					dialog.content(data);
				}, 200);
			});
		},
	});
	openButton.addEventListener("click", () => {
		dialog.open();
	});
}

async function initDialogMenu() {
	const openButton = document.querySelector("#openMenu");
	if (!openButton) return;
	const closeButton = document.querySelector("#closeMenu");
	if (!closeButton) return;

	const wasClickedInBackdrop = (e, ref) => {
		const { left, right, top, bottom } = ref.getBoundingClientRect();
		return (
			left > e.clientX ||
			right < e.clientX ||
			top > e.clientY ||
			bottom < e.clientY
		);
	};

	const menu = document.querySelector("#dialogMenu");
	openButton.addEventListener("click", () => {
		menu.showModal();
	});

	closeButton.addEventListener("click", () => {
		menu.close();
	});

	document.addEventListener("click", (e) => {
		if (e.target === document.documentElement) {
			wasClickedInBackdrop(e, menu) && menu.close();
		}
	});
}

async function initDialogUserReport() {
	const openButtons = document.querySelectorAll("[data-dialog='user-report']");
	if (!openButtons) return;

	for (const openButton of openButtons) {
		const reportId = openButton.dataset.id;

		const dialog = new UserReportDialog({
			classes: "min-h-[90svh] lg:min-h-[800px]",
			reportId,
			open: async (dialog) => {
				getUserReportModal(reportId).then((data) => {
					setTimeout(() => {
						dialog.content(data);
					}, 300);
				});
			},
		});
		dialog.success((data) => {
			if (data && data.action === "delete") {
				window.location.reload();
			}
		});
		openButton.addEventListener("click", () => {
			dialog.open();
		});
	}
}

function initLanguageDropdown() {
	const langDropdownBtn = document.querySelector("#language-dropdown");
	const languageDropdownTemplate = document.querySelector(
		"#language-dropdown-template",
	);
	if (!langDropdownBtn || !languageDropdownTemplate) return;

	const langDropdown = new Popper(langDropdownBtn, {
		trigger: "click",
		placement: "bottom-center",
	});
	langDropdown.setContent(languageDropdownTemplate);

	const langDropdownButtons = document.querySelectorAll(
		"button[data-language]",
	);
	if (!langDropdownButtons) return;

	for (const button of langDropdownButtons) {
		button.addEventListener("click", (e) => {
			const formData = new FormData();
			formData.append("language", button.dataset.language);

			fetch(window._galada.urls.setLanguage, {
				method: "POST",
				headers: {
					"X-CSRFToken": window._galada.csrfToken,
				},
				body: formData,
			}).then((response) => {
				if (response.ok) {
					window.location.reload(response.url);
				}
			});
		});
	}
}

function initSpinner() {
	const spinner = document.querySelector("#spinner-template");
	if (!spinner) return;

	window._galada.spinner = {
		element: spinner,
		show: () => {
			spinner.classList.remove("hidden");
		},
		hide: () => {
			spinner.classList.add("hidden");
		},
	};
}

function initSwitchTheme() {
	const switchThemeBtn = document.querySelector("#switch-theme");
	if (!switchThemeBtn) return;

	const switchThemeIcon = (theme) => {
		return `<img src="${
			theme === "light" ? moonSvg : sunSvg
		}" class="pointer-events-none" alt="Switch theme" />`;
	};

	const toggleTheme = async () => {
		const theme = localStorage.getItem("theme");
		const newTheme = theme === "light" ? "dark" : "light";
		// Dispatch event to notify other components
		window.dispatchEvent(
			new CustomEvent("themeChange", { detail: { newTheme: newTheme } }),
		);
		// Update the DOM
		document.documentElement.classList.remove(theme);
		document.documentElement.classList.add(newTheme);
		switchThemeBtn.innerHTML = switchThemeIcon(newTheme);
		localStorage.setItem("theme", newTheme);
	};

	const theme = localStorage.getItem("theme");
	if (!theme) {
		const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
		localStorage.setItem("theme", prefersDarkScheme.matches ? "dark" : "light");
	}
	switchThemeBtn.innerHTML = switchThemeIcon(theme);
	switchThemeBtn.addEventListener("click", async (event) => {
		if (
			!document.startViewTransition ||
			window.matchMedia("(prefers-reduced-motion: reduce)").matches
		) {
			toggleTheme();
			return;
		}

		await document.startViewTransition(() => {
			toggleTheme();
		}).ready;
	});
}

function initUserDropdown() {
	// Initialize user dropdown
	const userDropdownBtn = document.querySelector("#user-dropdown");
	const userDropdownTemplate = document.querySelector(
		"#user-dropdown-template",
	);
	if (userDropdownBtn && userDropdownTemplate) {
		const userDropdown = new Popper(userDropdownBtn, {
			trigger: "click",
			placement: "bottom-end",
		});
		userDropdown.setContent(userDropdownTemplate);
	}
}

document.addEventListener("DOMContentLoaded", async () => {
	// Initialize components
	initDialogMenu();
	initDialogUserReport();
	initDialogSourcesStatus();
	initLanguageDropdown();
	initSwitchTheme();
	initSpinner();
	initUserDropdown();

	// Add device to global object
	window._galada = window._galada || {};
	window._galada.isMobile = isMobile();
	window._galada.isTablet = isTablet();
});
